/* body * {
    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
} */

.head-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.head-container {
  width: 100%;
  margin: 0 auto;
  padding: 0px 0;
}
.mainLogo{
  height: 50px;
}

.logo {
  height: 50px;
    object-fit: contain;
}

.top-menu {
  display: flex;
  align-items: center;
}

.topbar-icon {
  width: 22px;
  margin-right: 2px;
}

.social-connect {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.social-connect-mobile {
  justify-content: center;
  margin: 10px 0 0px;
}

.top-menu-block {
  background-color: #c91b2b;
}

.social-icon {
  height: 30px;
  padding-top: 5px;
  color: #fff;
}

.top-menu-icon {
  color: #fff;
  display: flex;
  margin-right: 15px;
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  align-items: center;
}

.top-menu-icon a {
  color: #fff;
}

.top-menu-icon i {
  font-size: 18px;
  margin-right: 5px;
}

.social-connect-inner {
  display: flex;
  margin-left: 10px;
}

.menu-mt {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.menu-mt li {
  display: flex;
  list-style: none;
  padding: 0;
  margin-left: 50px;
  font-size: 16px;
  font-weight: 600;
}

.clients {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0 20px 0;
}

.about-text {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  text-align: center;
  margin: 0 0 10px 0;
}

.about-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.menu-mt li a {
  color: #333;
  position: relative;
}

.menu-mt li a * {
  -webkit-transition: all .5s ease;
  -moz-transition: all .5s ease;
  -ms-transition: all .5s ease;
  transition: all .5s ease;
}

.menu-mt li a::after {
  border-bottom: 2px solid #c91b2b;
  content: '';
  display: block;
  width: 0;
  z-index: -1;
  transition: .2s width ease;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.menu-mt li a:hover::after {
  width: 100%;
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: .45s;
  animation-duration: .45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fadeInSlide {
  -webkit-animation-name: fadeInSlide;
  animation-name: fadeInSlide;
  -webkit-animation-duration: .45s;
  animation-duration: .45s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.anime-delay {
  -webkit-animation-delay: 1s;
  animation-delay: .3s;
}

.anime-delay1 {
  -webkit-animation-delay: 1s;
  animation-delay: .4s;
}

.anime-delay2 {
  -webkit-animation-delay: 1s;
  animation-delay: .5s;
}

.anime-delay3 {
  -webkit-animation-delay: 1s;
  animation-delay: .6s;
}

.anime-delay4 {
  -webkit-animation-delay: 1s;
  animation-delay: .7s;
}

.anime-delay5 {
  -webkit-animation-delay: 1s;
  animation-delay: .8s;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes growIn {
  0% {
    opacity: 0;
    width: 0
  }

  100% {
    opacity: 1;
    width: 120%
  }
}

.btn-1 {
  border: 1px solid #c91b2b !important;
  color: #c91b2b !important;
}

.btn-2 {
  border: 1px solid #fff !important;
  color: #fff !important;
}

.btn-2 a {
  color: #fff !important;
}

.btnmt {
  border: none;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background: none;
  cursor: pointer;
  padding: 10px 15px;
  display: inline-block;
  margin: 10px 0 15px;
  text-transform: capitalize;
  font-family: 'Montserrat', sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
  outline: none;
  position: relative;
  transition: all 0.3s;
  border-radius: 3px;
}

.btn-1c:hover,
.btn-1c:active {
  color: #fff !important;
  z-index: 9;
}

.btn-1c:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
  background: #c91b2b !important;
}

.btn-2c:hover a,
.btn-2c:active a {
  color: rgba(0, 0, 0, 0.5) !important;
}

.btn-2c:after {
  width: 0%;
  height: 100%;
  top: 0;
  left: 0;
}

.btnmt:after {
  content: '';
  position: absolute;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-1c:hover:after,
.btn-1c:active:after,
.btn-2c:hover:after,
.btn-2c:active:after {
  width: 100%;
}

/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  background: #cdcdcd;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #999;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #c91b2b;
}

@media only screen and (max-width: 720px) {
  .head-container {
    width: 90%;
    margin: 0 auto;
  }

  .mobile-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .mt-menu-icon {
    font-size: 32px;
    margin-right: 20px;
  }

  .logo {
    height: auto;
  }

  .mobile-menu-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: #000;
  }

  .mobile-menu-footer {
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000;
  }

  .mobile-menu-footer p {
    display: flex;
    align-items: center;
    margin: 10px 0 10px;
    align-self: stretch;
    font-size: 15px;
    padding: 5px 10px;
  }

  .mobile-menu-wrapper {
    padding: 10px 0px;
    width: 320px;
    background: #000;
  }

  .mobile-menu-footer-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }

  .mobile-menumt {
    width: 100%;
  }
  .mobile-menumt h1{
    font-size: 15px;
    color: #fff;
    padding: 10px 15px;
    text-transform: uppercase;
    margin: 0px;
    font-weight: 600;
    padding-left: 30px;
  }
  .MobileAccordian-content{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .MobileAccordian-content a{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: #f1f1f1;
    padding: 10px 15px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .mobile-menumt li {
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #464545;
    background: #000;
  }

  .mobile-menumt li a {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .mobile-menumt li svg{
    color: rgb(255, 255, 255);
    font-size: 22px;
  }

  .clients {
    padding: 20px 0 20px 0;
  }

  .about-text-wrapper {
    margin-bottom: 0px;
  }

  .menu-divider {
    border-bottom: 2px solid rgba(0, 0, 0, 0.12);
    content: "";
    width: 100%;
    margin-bottom: 20px;
  }

  .mobile-menu-icon {
    width: 25px;
    filter: opacity(0.8);
    margin-right: 5px;
  }

  .icons {
    margin-right: 5px;
    width: 22px;
  }
}

@media only screen and (min-width: 720px) and (max-width: 992px) {
  .head-container {
    width: 90%;
    margin: 0 auto;
  }

  .mobile-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mt-menu-icon {
    font-size: 32px;
  }

  .logo {
    height: 55px;
  }

  .mobile-menu-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background: #000;
  }

  .mobile-menu-footer {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #000;
  }

  .mobile-menu-footer p {
    display: flex;
    align-items: center;
    margin: 10px 0 10px;
    align-self: stretch;
    font-size: 15px;
    padding: 5px 10px;
  }

  .mobile-menu-wrapper {
    padding: 10px;
    width: 320px;
  }

  .mobile-menu-footer-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 0 10px;
  }

  .mobile-menumt {
    width: 250px;
  }

  .mobile-menumt li {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-menumt li svg{
    color: rgb(255, 255, 255);
    font-size: 22px;
  }
  .mobile-menumt li a {
    font-size: 15px;
    color: #fff;
    font-weight: 500;
  }

  .clients {
    padding: 20px 0 20px 0;
  }

  .about-text-wrapper {
    margin-bottom: 0px;
  }

  .menu-divider {
    border-bottom: 3px solid #cdcdcd;
    content: "";
    width: 100%;
    margin-bottom: 20px;
  }

  .mobile-menu-icon {
    width: 25px;
    filter: opacity(0.8);
    margin-right: 5px;
  }

  .icons {
    margin-right: 5px;
    width: 22px;
  }
}

#AnaMenu {
  position: relative;
  margin: 0 0 0 0px;
  padding: 0px 0px 0 0px;
  border: 0;

  /* display: none; */
}

#nav {
  display: block;
  background: transparent;
  margin: 0;
  padding: 0;
  border: 0
}

#nav ul {
  float: none;
  display: block;
  
  margin: 0px 0 0 0;
  border: 0;
  padding: 15px 0 3px 0;
  overflow: visible;
  transition: all .3s ease 0s;
  box-shadow: 0 8px 30px rgb(0 0 0 / 4%);
  /* border-top: 5px solid #ED1B24; */
  line-height: initial;
}

#nav ul li {
  border: 0px solid #2d2d2d;
  font-weight: 500;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  width:100%;
}

#nav li a,
#nav li a:link,
#nav li a:visited {
  color: #fff;
  display: block;
  text-decoration: none;
  margin: 0;
  letter-spacing: 0.6px;
  padding: 0px 10px 0px 10px;
  display: flex;
  justify-content: space-between;
  width: auto;
  align-items: center;
  font-weight: 600;
  font-size: 13.5px;
  text-transform: uppercase;
  line-height: 80px;
  width: 100%;
}
.submenuhover:hover{
  background: none !important;
}
#AnaMenu ul li a:hover{
  background: #e61d24;
}
#AnaMenu ul li .nobg:hover{
  background: #000;
}
/* #nav li a:hover {
  color: #ffffff;
  margin: 0;
  border: 0;
  background: #ED1B24;
} */

.mainc:hover{
  background: #ee1923;
  color: #fff;
    width: auto;
}

#nav li li a,
#nav li li a:link,
#nav li li a:visited {
  background: none !important;
  border-top-color: transparent;
  color: #f1f1f3;
  float: none;
  margin: 0;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  line-height: 45px;
  font-size: 16px;
  padding-left: 10px !important;
  padding-right: 20px !important;
  height: 30px;
  width:100%;
}
#nav ul ul{
  border-top: 0px solid #ED1B24 !important;
}
.Faqs p {
  color: #fff;
  font-size: 19px;
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  font-weight: 600;
}

/* #nav li ul::after {
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ED1B24;
  left: calc(10% - 10px);
  transition: border-width 220ms ease, right 220ms ease;
  top: -12px;
  clear: both;
  content: ' ';
  position: absolute;
  height: 0;
  width: 0;
} */

#nav li ul ul::after {
  border-left: 0px solid transparent;
  border-right: 0px solid transparent;
  border-bottom: 0px solid #ED1B24;
  left: calc(30% - 10px);
  transition: border-width 220ms ease, right 220ms ease;
  top: -12px;
  clear: both;
  content: ' ';
  position: absolute;
  height: 0;
  width: 0;
  display: none !important;
}
#nav li li a {
  font-size: 14px;
}

#nav li li a:hover,
#nav li li a:active {
  background: #ED1B24;
  color: #fff;
  padding: 8px 12px;
  border: 0;
  text-decoration: none;
}
#nav li ul li:hover{
  background: #ED1B24;
  color: #fff;
  width: 92%;
}
#nav li ul ul li:hover{
  background: none !important;
  color: #000;
}
#nav li {
  float: none;
  display: inline-block;
  margin: 0;
  padding: 0;
  border: 0;
  color: #f1f1f3;
  padding: 0px 0px;
  height: 80px;
  line-height: 90px;
}

#nav li ul {
  position: absolute;
  left: -999em;
  width: auto;
  background: #000;
  top: 80px;
}
.mainc {
  font-family: Montserrat;
  font-weight: 600;
  /* height: 50px !important;
  line-height: 50px !important; */
  color: #fff;
    text-decoration: none;
    margin: 0;
    letter-spacing: 0.6px;
    padding: 0px 15px 0px 15px;
    display: flex;
    justify-content: space-between;
    width: auto;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
}
.mainc a{
  height: 50px !important;
  line-height: 50px !important;
}
/* #nav li ul a {
  height: 50px !important;
  line-height: 50px !important;
} */

#nav li ul li ul {
  z-index: 9999;
 
 
  height: auto;
  width: 350px;
  margin: 0;
  padding: 0;
  background: #000000;
 
}

#nav li ul a {
  width: 190px;
  border: 0;
  text-decoration: none;
  font-size: 13px;
  color: #fff;
    transition: all .3s ease-in-out;
}
#nav li ul li{
  height: 30px !important;
  line-height: 30px !important;
}

#nav li ul ul {
 background: none !important;
  padding: 0px 0px 0px 0px;
    display: block !important;
    position: relative !important;
    left: 0px !important;
    top: 0px !important;
}

#nav li:hover ul ul,
#nav li:hover ul ul ul,
#nav li.sfhover ul ul,
#nav li.sfhover ul ul ul {
  left: -999em;
}

#nav li:hover ul,
#nav li li:hover ul,
#nav li li li:hover ul,
#nav li.sfhover ul,
#nav li li.sfhover ul,
#nav li li li.sfhover ul {
  left: auto;
}

#nav li:hover,
#nav li.sfhover {
  position: static;
}

.tab-menu>button {
  cursor: pointer;
  padding: 8px 16px;
  border: 0;
  border-bottom: 2px solid transparent;
  background: none;
}

.tab-menu>button.focus {
  border-bottom: 2px solid #007bef;

  padding: 11px 72px !important;
}

.tab-menu>button:hover {
  /* border-bottom: 2px solid #007bef; */
}

.tabMenuProduct {
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.tab-menu {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #37455E;
}

.navLink99 {
  display: flex;
  width: 77.5%;
  justify-content: space-between;
}

.nav_link1 {
  padding: 14px 40px;
}

.nav_link1 {
  list-style-type: none;
  margin: 0;

}

.nav_submenu {
  padding-right: 15px;
}

.nav_submenu {
  display: inline;
}

.nav_submenu a {
  color: #37455E;
  font-weight: revert;
  /* color: #4a4a4a; */
}


.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  padding-left: 1rem;
}

.embla__slidetL {
  flex: 0 0 100%;
  min-width: 0;
  margin-left: 1rem;
  background-color: rgb(0 0 0 / 50%);
}

.embla__slidet {
  flex: 0 0 33%;
  min-width: 0;
  margin-left: 1rem;
  background-color: rgb(0 0 0 / 50%);
}
.embla__slidetS {
  flex: 0 0 32%;
  min-width: 0;
  margin-left: 1rem;
  background-color: rgb(0 0 0 / 50%);
  height: 570px;
}

.embla__slide__img {
  border-radius: 0px;
  display: block;
  width: 100%;
  object-fit: cover;
}

.embla__controls {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 0rem;
}

.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
  position: absolute;
  z-index: 99;
  top: 45%;
  width: 97%;
  left: 0px;
  margin: 0 auto;
}

.embla__button--prev {
  left: 30px;
  position: absolute;
}

.embla__button--next {
  right: 0px;
  position: absolute;
}

.embla__button {
  /* -webkit-tap-highlight-color: rgba(3, 44, 53, 0.9); */
  -webkit-appearance: none;
  appearance: none;
  background: none !important;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  /* box-shadow: inset 0 0 0 0.2rem rgb(234, 234, 234); */
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.embla__button:disabled {
  color: rgb(192, 192, 192);
}

.embla__button__svg {
  width: 65%;
  height: 65%;
}

.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}

.embla__dot {
  -webkit-tap-highlight-color: rgba(45, 49, 57, 0.9);
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 1.7rem;
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid rgb(45, 49, 57);
  margin-right: 5px;
}

.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem rgb(45, 49, 57);
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
  background: rgb(45, 49, 57);
}

.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem #ED1B24;
  background: #ED1B24;
}

.embla__slide__img {
  user-select: none;
}


.embla {
  max-width: 100%;
  margin: auto;
  --slide-height: 40rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(1rem * -1);
}

@media only screen and (max-width: 1020px) and (min-width: 320px) {
  .embla__slide__img {
    height:auto !important;
    object-fit: cover !important;
    object-position: left !important;
  }
  .right1 {
    display: block !important;
}
.formGridLand {
  width: 100% !important;
}
  textarea {
    width: 100%;
    height: 13rem !important;
    background-color: transparent;
  }
  .embla__parallax__layerba-2-2{
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 0 auto;
  }
  .embla__parallax__layerba-2{

  }

  .form-groupH {
    flex-direction: column !important;
}
.form-groupH label{
  margin-bottom: 10px;
}
  .tetstL {
    padding-bottom: 20px !important;
    margin: 20px auto 20px auto !important;
}
.embla__controlss {
  margin-top: 0px !important;
}

  .imgp-22 {
    min-width: 100% !important;
    padding: 0px !important;
}
.embla__controlsba-2{
   margin-top: 0px !important;
}
.ismobtest{
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.TestM {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
  .embla__slide__imgba-26 {
    border-radius: 0rem;
    display: block;
    width: 100%;
    object-fit: cover;
}
.courseUpdatesl ul li span {
  font-size: 14px !important;
  font-weight: 700;
  color: #fff;
  text-align: center;
}
  .testimonial-imageh {
    width: 80% !important;
    object-fit: cover !important;
    object-position: bottom !important;
    margin: 0 auto !important;
    display: flex !important;
}
.featuress2 li img{
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.featuress2 li{
  border-bottom: 1px solid #555;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
}
#chapter100 {
  min-height: 740px !important;
  background-size: cover;
}
  .mainCourse1 {
    flex-direction: column;
}
.mainHeadingTotall-2 {
  font-size: 21px !important;
        line-height: initial;
        width: 90% !important;
        margin: 20px auto 20px auto !important;
}
.CountryLogos {
  grid-template-columns: repeat(4, 1fr) !important;
  padding: 0rem 0% 0rem 0% !important;
  grid-column-gap: 0rem !important;
  width:100% !important;
  margin: 0px auto !important;

}
.CountryLogoss {
  grid-template-columns: repeat(1, 1fr) !important;
  padding: 0rem 0% 1rem 0% !important;
}
.MainVideoSection{
  width: 100% !important;
  margin-top: 40px !important;
}
.videomain2 video {
  width: 100% !important;
  height: 100% !important;
  margin: 0 auto;
}
.videomain2 {
  width: 100% !important;
  height: 100% !important;
}
.MainVideoSection h3 {
  font-size: 24px;
  font-weight: 600;
  width: 90% !important;
}
.featuress2 {
  width: 90% !important;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr) !important;
  grid-column-gap: 0rem !important;
  padding: 0rem 0% 0rem 0% !important;
  margin-top: 20px;
  margin: 0 auto;
  margin-bottom: 30px !important;
}
.tcontent button {
  justify-content: center !important;
}
.featuress3 {
  width: 90% !important;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr) !important;
  grid-column-gap: 0rem !important;
  padding: 0rem 0% 0rem 0% !important;
  margin-top: 20px;
  margin: 0 auto;
}
.featuress3g {
  width: 90% !important;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr) !important;
  grid-column-gap: 0rem !important;
  padding: 0rem 0% 0rem 0% !important;
  margin-top: 20px;
  margin: 0 auto;
}
.featuress3u {
  width: 90% !important;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(1, 1fr) !important;
  grid-column-gap: 0rem !important;
  padding: 0rem 0% 0rem 0% !important;
  margin-top: 20px;
  margin: 0 auto;
}
.lefthd {
  max-width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
}
.lefth {
  margin: 0px auto !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.featuresscon2 {
  height: 68px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 20px;
  color: #fff;
  border-radius: 10px;
  width: 90%;
  font-size: 19px;
  font-weight: 500;
}
.featuresscon2 {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 10px !important;
  color: #fff;
  border-radius: 10px;
  width: 90%;
  font-size: 15px !important;
  font-weight: 500;
}
.testimonial-paragraph {
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: center !important;
  letter-spacing: 0.7px;
  width: 90% !important;
}
.featuress3 li {
  border-bottom: 1px solid #555;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.featuress3 li img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.featuress3g li {
  border-bottom: 1px solid #555;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.featuress3g li img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.featuress3u li {
  border-bottom: 1px solid #555;
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.featuress3u li img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.faq-container {
  max-width: 90% !important;
  margin: 0 auto;
}
.embla__buttonsba-2 {
  width: 94% !important;
  left: -10px !important;
}
.CousellingButton {
  padding: 20px 0px !important; 
  font-size: 17px !important;
  z-index: 9;
}
.containers-2 {
  max-width: 100% !important;
  padding: 20px !important;
  margin: 0 10px !important;
  border-radius: 0px;
  overflow: hidden;
  background: #222;
  box-shadow: 0 15px 20px rgba(0, 0, 0, .6);
}
.imgp-2 {
  min-width: 100% !important;
  padding: 0px !important;
}
.mainCourseLeft1 {
  width: 100% !important;
  height: auto !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.courseUpdatesl ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column !important;
}
.mainHeadingTotall {
  font-size: 24px !important;
}
#mobileview{
  display: block !important;
}
#desview{
  display: none;
}
.mainCourseRight1 {
  width: 100% !important;
  height: auto !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 3%;
  flex-direction: column;
}
.mainCourseRight1 h2 {
  color: #fff;
  font-size: 15px !important;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 9px !important;
  margin-bottom: 20px;
  line-height: 30px !important;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.mainCourseRight1 p {
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 0px !important;
  margin-bottom: 0px;
  line-height: 30px;
  width: 100% !important;
}
.mainCourse1 {
  height: 100% !important;
}
  .mbimgland{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-direction: column;
    background: #222;
    margin: 0px auto;
  }
  .mbimgland li{
  padding: 10px;
  }
  .mbimgland p{
  color: #fff;
      font-size: 16px;
      text-align: left;
      width: 90%;
      margin: 0 auto;
  }
  .mbimgland h3{
    font-size: 20px;
        color: #fff;
        text-align: left;
        font-weight: 400;
        margin: 3px;
        line-height: 28px;
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
    
    }
    .mbimgland svg {
      font-size: 24px;
      color: #fff;
      width: 30px;
      height: 30px;
  }
  .mbimgart{
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    flex-direction: column;
  
  }
  .mbimgart img{
    width: 100%;
  }
  .embla__controlssL{
    top: -440px;
  }
  .Objectives li{
    width: 85% !important;
    text-align: left !important;
    color: #fff;
    margin-bottom: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    line-height: 32px !important;
    font-weight: 400 !important;    
  }
  .sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 9999;
    left: 0px;
}
.mobile-sticky-footer {
  background-color: #ed1923;
  border-radius: 0px 0px 0px 0px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: #fff;
  font-size: 18px;
  width: 100%;
}
  .courseUpdates1 ul li p {
    width: 100% !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}
.bottomFootLeft{
  display: flex;
    align-items: center;
    justify-content: center;
    width: inherit;
}
.bottomFootLeft svg{
  margin-right: 7px;
}
.counter p {
  font-size: 20px !important;
  font-weight: 300 !important;
}

.bottomFootRight{
  display: flex;
    align-items: center;
    justify-content: flex-start;
    width: inherit;
}
.bottomFootLeft a{
  color: #fff;
  display: flex;
    align-items: center;
    justify-content: flex-start;
}
.bottomFootRight a{
  color: #fff;
  display: flex;
    align-items: center;
    justify-content: flex-start;
}
.bottomFootRight svg{
  margin-right: 7px;
}
  .Objectives1 li {
    width: 85% !important;
    text-align: left !important;
    color: #fff;
    margin-bottom: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    line-height: 32px !important;
    font-weight: 400 !important;
    font-size: 16px !important;
}
  .embla__buttonsba{
    width: 90% !important;
  }
  .formGrid {
    grid-template-columns: repeat(1, 1fr) !important;
}
.tuitiona-aid-sectionsq {
  width: 100% !important;
  margin: 0 auto;
}
  .embla__button--prev{
    left: 10px !important;
  }
  .scroll-to-top{
    bottom: 11rem !important;
  }
  .mobilehead{
    margin-top: 20px !important;
  }
  .card__collection .cards{
    width: 100% !important;
  }
  .embla__button__svg {
    width: 65% !important;
    height: 65% !important;
}
.ribbon span {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  line-height: 27px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 21.25rem !important;
  display: block;
  position: absolute;
  top: 4.188rem !important;
  right: -3.313rem !important;
  height: 27px;
  z-index: 9999;
}
.ribbon {
  position: absolute;
  right: -3.75rem !important;
  top: -2.75rem !important;
  z-index: 99999;
  overflow: hidden;
  width: 190px !important;
  height: 190px !important;
  text-align: right;
}

.ribbon1 span {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  line-height: 27px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 21.25rem !important;
  display: block;
  position: absolute;
  top: 4.188rem !important;
  right: -3.313rem !important;
  height: 27px;
  z-index: 9999;
}
.ribbon1 {
  position: absolute;
  right: -3.75rem !important;
  top: -2.75rem !important;
  z-index: 99999;
  overflow: hidden;
  width: 190px !important;
  height: 190px !important;
  text-align: right;
}
.embla__button__svgb {
  width: 65% !important;
  height: 65% !important;
}
  .suprts {
    
    margin-top: 0px !important;
    text-align: left !important;
}
.title {
  font-size: 26px!important;
}
.et_pb_section_0 {
  min-height: 270px !important;
}
.fom-ad {
  height: auto !important;
}
  .banner .heading--white {
    line-height: 38px !important;
    font-size: 28px !important;
    transform: translate(0);
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    letter-spacing: 1px !important;
    margin: 0px 20px !important;
}
.embla__slideb{
  flex: 0 0 100% !important;
}
.embla__slide__imgb {
  height: 30rem !important;
}
.mainHeadingTotal{
  font-size: 24px !important;
  line-height: inherit;
}
.card-list {
  display: grid;
  grid-template-columns: repeat(2,minmax(0,1fr)) !important;
}
.container-full {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.container-full1 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.embla__slidet {
  flex: 0 0 100%;
  min-width: 0;
  margin-left: 1rem;
  background-color: rgb(0 0 0 / 50%);
}
.embla__slide__number {
  height: auto !important;
}
#chapter1 {
  height: auto !important;
}
#chapter2 {
  height: auto !important;
}
}

.embla__slide__img {
  border-radius: 0px;
  display: block;
  height: 36rem;
  width: 100%;
  object-fit: cover;
  margin-top: 0rem;
}


.embla__parallax {
  border-radius: 0px;
  height: 100%;
  overflow: hidden;
}

.embla__parallax__layer {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.embla__parallax__img {
  max-width: none;
  object-fit: cover;
}



.siva{
  position: relative;
  padding: 1.3rem 0;
  text-align: center;
  overflow: hidden;
  background-position: center;
  background-size: cover;
}

.banner .container {
  display: flex;
  align-items: center;
}

.banner__heading {
  display: inline-block;
}

.banner__heading {
  color: inherit;
  display: block;
  margin: 0;
  z-index: 1;
  flex-grow: 1;
}

.banner .heading--white {
  line-height: 51px;
    font-size: 45px;
    transform: translate(0);
    color: #fff;
    text-transform: capitalize;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 20px 0px;
}

.banner__button:hover, a.banner__button:hover {
  border-color: #ED1B24;
  color: #fff;
  transition: all;
  background: #ED1B24;
  cursor: pointer;
}

.banner__button,
a.banner__button {
  float: right;
  min-width: 10rem;
  width: auto;
  margin: 0 0rem;
}

.banner__button,
a.banner__button {
  background: #fff;
  border: none;
  color: #197272;
}
.sivatet{
  
    background-position: bottom;
    background-size: contain;
}


.buzzdiv{
  position: absolute;
    background: rgb(0, 0, 0, 0.7);
    color: #fff;
    padding: 15px 30px;
    bottom: 0px;
    width: 100%;
}
.buzzdiv h3{
font-size: 19px;
margin: 5px 0px;
font-weight: 500;
text-align: center;
height:30px !important;
display: flex;
align-items: center;
justify-content: center;
}
.buzzdiv p{
font-size: 14px;
margin-top: 10px;
font-weight: 300;
}
.rfm-initial-child-container {
  height: 110px;
}

.embla__buttontest {
  /* -webkit-tap-highlight-color: rgba(3, 44, 53, 0.9); */
  appearance: none;
  background: none !important;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  /* box-shadow: inset 0 0 0 0.2rem rgb(234, 234, 234); */
  width: 2.2rem;
  height: 2.2rem;
  z-index: 1;
  border-radius: 50%;
  color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.viewallcourses{
  width: 220px;
  font-size: 16px;
  float: right;
  margin: 20px 0px;
  background: none !important;
  color:#000;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.viewallcourses:hover{
  text-decoration: underline;
}

.chapter{
  position: relative;
  height: 1400px;
  z-index: 1;
}

.chapterL{
  position: relative;
  z-index: 1;
}

#chapter1{
background: url(../assets/img/banners/902624.webp) 50% 0 no-repeat fixed;
  height:1350px;
  background-size: cover;
}
#chapter100{
  background: url(../assets/img/banners/902624.webp) 50% 0 no-repeat fixed;
  min-height: 740px;
    background-size: cover;
  }
#chapter2{
  height:390px;
  background-size: cover;
  margin-top:25px;
}
#chapter3{
  height:520px;
  background-size: cover;
  margin-top:25px;
}
.mainc{
  font-family: Montserrat;
  font-weight: 600;
}
#nav li li a:hover{
  background-color: #ED1B24 !important;
  width:100%;
}
.durga{
  background-image: url(../assets/img/partners/calls.png);
}
.rammm{
    z-index: 1;
    background:#000;
    border-radius: 0;
    min-height: 100vh;
    margin-bottom: 0;
    /* padding-top: 10em; */
    padding-bottom: 0em;
    /* position: fixed !important; */
    top: auto;
    bottom: 0%;
    left: 0%;
    right: 0%;
    z-index: 9;
}

.grid-containersss {
  z-index: 100;
  width: 100%;
  height: 100vh;
  padding-left: 3.5em;
  padding-right: 3.5em;
  display: none;
  position: fixed;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}
.pr9{
  position: relative;
  z-index: 1;
}



.embla__slideb {
  transition: all .2s ease-in-out;
}
.embla__slideb:hover {
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
    transform: translateY(-4px) scale(1.03) !important;
    -webkit-box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.1);
}
.scroll-to-top {
  background: #ed1923 !important;
  right: 2rem !important;
  bottom: 8rem !important;
  position: fixed;
  z-index: 99;
  cursor: pointer;
  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
  box-shadow: 0 9px 25px 0 rgba(132, 128, 177, 0.28);
  border: none;
  font-size: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scroll-to-top svg{
    color: #fff;
    font-size: 21px;
    width: 30px;
    font-weight: 600;
}
.scroll-to-top:focus{
  background-color: #ed1923 !important;
}
nav {
  color: #fff;
  width: 100%;
  height: 56px;
  line-height: 56px;
}
.bg-c-gradient300 {
  background-image: linear-gradient(82deg, #2f3550, rgba(47, 53, 80, .5) 51.56%, #2f3550);
}
.v-c-h900 {
  font-size: 48px !important;
  font-weight: 600;
  letter-spacing: .96px;
  line-height: 1.5;
  text-transform: uppercase;
  margin-top: 0px;
  margin-bottom: 0px;
}
.mainHeadingTotal{
  font-size: 48px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  letter-spacing: 1px;
  text-align: center;
}
.form-stcc{
  padding-top: 10px;
  padding-bottom: 25px;
  background-color: #222;
  border: 1px solid rgba(86, 61, 124, .2);
}
.formMain{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.formGrid{
    width: 90%;
    display: flex;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    padding: 0.7rem 0% 0rem 0%;
}
.formGrid label{
  color: #fff;
  font-size: 17px;
  margin-bottom: 10px;
  display: flex;
  letter-spacing: 1px;
  width: 100%;
}
.formGrid select{
  background: none;
  width: 100%;
  padding: 5px;
  border: 1px solid #f2f2f2;
  border-radius: 2px;
  height: 42px;
  display: block;
  color: #fff;
  font-size: 16px;
}
.formGrid select option{
 font-size: 12px;
 font-weight: 800;
  color: #000;
}
.formGrid select option:hover{
  font-size: 12px;
  font-weight: 800;
   color: #000;
 }
 select {
  color: #4747 47;
}
option:not(:first-of-type) {
  color: black;
}
/* the modification */
option:first-of-type {
  display: none;
}
.button.brand {
  background-color: transparent !important;
  background-image: linear-gradient(-45deg,transparent 12px,#ec1923 0,#ec1923 calc(100% - 12px),transparent calc(100% - 12px),transparent);
  border-width: 0!important;
  margin: 8px;
  z-index: 1;
}
.button.size200 {
  font-family: 'Montserrat', sans-serif;
  font-size: 14.5px;
  font-weight: 500;
  height: 45px;
  line-height: 1.48;
  padding-left: 24px;
  padding-right: 24px;
  text-transform: uppercase;
}
.button.size201 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  height: 45px;
  line-height: 1.48;
  padding-left: 24px;
  padding-right: 24px;
  text-transform: uppercase;
  color:#fff;
}
.incontent{
  background: #222;
  color: #000;
  height: 130px;
}
.incontent p{
  color: #fff !important;
    font-weight: 600;
    font-size: 17px !important;
    padding: 10px 30px;
    text-align: center;
}

.incontent1{
  background: #000;

}
.size201:hover {
 color:#ed1923;
}
.button.brand:hover {
  background-image: linear-gradient(-45deg,transparent 12px,#ec1925 0,#ec1925 calc(100% - 12px),transparent calc(100% - 12px),transparent);
}
.tuitiona-aid-sections{
  display: flex;
  align-items: stretch; /* Ensures both boxes have the same height */
  gap: 5px; 
}
.tuitiona-aid-sectionsq{
  width: 90%;
  margin: 0 auto;
}
.tuitiona-aid-sectionsInner {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1.5rem;
  padding: 0.7rem 0% 0rem 0%;
  width: 100%;
}
.tuitiona-aid-sectionsInnerq {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1.5rem;
  padding: 0.7rem 0% 0rem 0%;
  width: 100%;
}
.bg-c-gradient300 {
  background-image: linear-gradient(82deg,#2f3550,rgba(47,53,80,.5) 51.56%,#2f3550);
}
.w-full {
  width: 100%;
}
.absolute {
  position: absolute;
}
.left-0 {
  left: 0;
}
.-top-\[1px\] {
  top: -1px;
}
.h-\[101\%\] {
  height: 101%;
}
.z-\[2\] {
  z-index: 1;
}



div.et_pb_section.et_pb_section_0 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.et_pb_section_0.et_pb_section {
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #222222!important;
}
.et_pb_section_0 {
  width: 100%;
}
.et_pb_section_0 {
  min-height: 500px;
  max-width: 100%;
  margin-left: auto!important;
  margin-right: auto!important;
  z-index: 1;
  position: relative;
}
.et_pb_section {
  padding: 54px 0;
}
.et_pb_all_tabs, .et_pb_module, .et_pb_posts_nav a, .et_pb_tab, .et_pb_with_background {
  background-repeat: no-repeat;
}
.et_pb_section {
  background-size: cover;
}
.et_pb_row_0.et_pb_row {
  padding-top: 0px!important;
  padding-bottom: 3%!important;
  padding-top: 0px;
  padding-bottom: 3%;
}
.et_pb_text_0 {
  padding-top: 0px!important;
  padding-bottom: 0px!important;
  margin-bottom: 9px!important;
  margin-top: auto;
}
.et_pb_text_inner {
  position: relative;
}
.et_pb_text>:last-child {
  padding-bottom: 0;
}
.et_pb_text_0 h1 {
  font-size: 48px;
  text-align: center;
  font-weight: 600;
}
.white-txt {
  color: white!important;
}
.title {
  font-size: 48px;
}
.et_pb_row:after, .et_pb_row_inner:after {
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
  width: 0;
}
.CoursesOffered{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(../assets/img/banners/courses.webp)!important;
}
.MobileClass{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 100%,#0a0a0a 100%),url(./../assets/img/mobilebanner/maincourse.webp)!important;
  height: 378px;
    background-position: center;
    background-size: cover;
}
.bsc{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/BachelorinComputerScienceandGameDevelopment.webp)!important;
}
.bsc1{
  background-image: url(./../assets/img/banners/without-enroll-now.webp)!important;
  height:600px;
}
.Diploma-in-Game-Design-Production{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 10%,#0a0a0a 100%),url(./../assets/img/banners/Diploma-in-Game-Design-Production.webp)!important;
}
.Diploma-In-3d-Environment-Art-Creation-For-Games{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 10%,#0a0a0a 100%),url(./../assets/img/banners/Diploma-In-3d-Environment-Art-Creation-For-Games.webp)!important;
}
.Diploma-in-Game-Development-with-Unity{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 10%,#0a0a0a 100%),url(./../assets/img/banners/Diploma-in-Game-Development-with-Unity.webp)!important;
}
.Bachelors-in-Computer-Science-Game-Development{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 10%,#0a0a0a 100%),url(./../assets/img/banners/Bachelors-in-Computer-Science-Game-Development.webp)!important;
}
.Bachelors-in-Game-Art-Design{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 10%,#0a0a0a 100%),url(./../assets/img/banners/Bachelors-in-Game-Art-Design.webp)!important;
}
.Bachelors-in-Augmented-Reality-Virtual-Reality{
  background-image: linear-gradient(350deg,rgba(0,0,0,0) 0%,#0a0a0a 100%),url(./../assets/img/banners/Bachelors-in-Augmented-Reality-Virtual-Reality.webp)!important;
}
.Advanced-Diploma-in-Game-Development{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 10%,#0a0a0a 100%),url(./../assets/img/banners/Advanced-Diploma-in-Game-Development.webp)!important;
}
.Advanced-Diploma-Traditional-Digital-Concept-Art{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 10%,#0a0a0a 100%),url(./../assets/img/banners/Advanced-Diploma-in-Traditional-Digital-Concept-Art.webp)!important;
}
.Advanced-Diploma-in-3D-Game-Art-Digital-Sculpting{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 10%,#0a0a0a 100%),url(./../assets/img/banners/Advanced-Diploma-in-3D-Game-Art-Digital-Sculpting.webp)!important;
}
.Masters-in-Game-Technology{
  background-image:linear-gradient(180deg,rgba(0,0,0,0) 10%,#0a0a0a 100%),url(./../assets/img/banners/Masters-in-Game-Technology.webp)!important;
}
.master2{
  background-image: url(./../assets/img/banners/bspgtm.webp)!important;
  height:600px;
}
.master1{
  background-image: url(./../assets/img/banners/masterd.webp)!important;
  height:600px;
  background-size: contain;
}
.masterMobileClass2{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/bspgt.webp)!important;
  height:430px;
}
.masterMobileClass{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/mmaster.webp)!important;
  height:430px;
}
.bscadv{
  background-image: url(./../assets/img/banners/adv-dipolma.webp) !important;
  height:600px;
}
.lga{
  background-image: url(./../assets/img/banners/Game-Art.webp)!important;
  height:770px;
}
.lga:hover{
 color:#e61d23;
}
.mobilelga{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 100%,#0a0a0a 100%),url(./../assets/img/banners/Game-Artm.webp)!important;
  height: 487px;
    background-size: cover;
    bottom: -3px;
}
.Unity-Developerm{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 100%,#0a0a0a 100%),url(./../assets/img/banners/Unity-Developerm.webp)!important;
  height: 487px;
}
.Unity-Developer{
  background-image: url(./../assets/img/banners/Unity-Developer.webp)!important;
  height:700px;
}
.Unreal-Developer{
  background-image: url(./../assets/img/banners/Unreal-Developer.webp)!important;
  height:600px;
}
.Unreal-Developerm{
  background-image: url(./../assets/img/banners/Unreal-Developerm.webp)!important;
  height: 487px;
}
.Game-Development{
  background-image: url(./../assets/img/banners/Game-Development.webp)!important;
  height:700px;
}
.Game-Developmentm{
  background-image: url(./../assets/img/banners/Game-Developmentm.webp)!important;
  height:487px;
}
.bsc2{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/without-enroll-now.webp)!important;
  height:600px;
  background-size: contain !important;
}

.bsc22{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/top.webp)!important;
  height:600px;
  background-size: contain !important;
}


.MobileClassbsc{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/mobilebanner/bcsgd.webp)!important;
  background-position: center;
    height: 430px;
}
.MobileClassbsc1{
  background-image: url(./../assets/img/banners/without.webp)!important;
  background-position: center;
  height:450px;
}
.MobileClassbsc12{
  background-image: url(./../assets/img/banners/topM.webp)!important;
  background-position: center;
  height:450px;
}
.contactUsbanner{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/Contactus.webp)!important;
}
.affilationbanner{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/Affiliation.webp)!important;
}

.gamedesignandart{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/mobilebanner/gad.webp)!important;
}
.MobileClassarvr{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 100%,#0a0a0a 100%),url(./../assets/img/mobilebanner/arvr.webp)!important;
  height:430px
}
.arvr{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/arvr.webp)!important;
}
.Placement{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/jobs.webp)!important;
}
.AdmissionsB{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/Admissions.webp)!important;
}
.finaceB{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/Financialassistance.webp)!important;
}
.AssistanceB{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/alumni-success.webp)!important;
}
.ShowcaseB{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/student_showcase.webp)!important;
}
.AcceleratorB{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/acceleratorprogram.webp)!important;
}
.HostelB{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/hostel.webp)!important;
}
.StoryB{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/aboutus.webp)!important;
}
.MobileClassb{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 100%,#0a0a0a 100%),url(./../assets/img/mobilebanner/ourstory.webp)!important;
  height:450px
}
.MobileClassaff{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 100%,#0a0a0a 100%),url(./../assets/img/mobilebanner/Affiliation.webp)!important;
  height:420px;
}
.MobileClasspls{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/mobilebanner/Placementsupport.webp)!important;
  background-position: center;
  height:420px;
}
.MobileClassadmis{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 100%,#0a0a0a 100%),url(./../assets/img/mobilebanner/Admissions.webp)!important;
  height:450px;
}
.MobileClassass{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/mobilebanner/alumnisuccess.webp)!important;
}
.MobileClassshow{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 100%,#0a0a0a 100%),url(./../assets/img/mobilebanner/Studentshowcase.webp)!important;
  height:420px;
}
.MobileClassacctr{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 100%,#0a0a0a 100%),url(./../assets/img/mobilebanner/acceleratorprogram.png)!important;
  background-position:center;
  height: 420px;
}
.MobileClasshstl{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/mobilebanner/hostel.webp)!important;
  background-position:center;
}
.MobileClasscont{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/mobilebanner/Contactus.webp)!important;
  background-position:center;
}
.masterd1{
  background-image: linear-gradient(180deg,rgba(0,0,0,0) 50%,#0a0a0a 100%),url(./../assets/img/banners/masterd.webp)!important;
  height:600px;
  background-size: contain !important;
}
.ribbon {
  position: absolute;
  right: -1.75rem;
  top: -1.75rem;
  z-index: 1;
  overflow: hidden;
  width: 140px;
  height: 140px;
  text-align: right;
}
.ribbon span {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  line-height: 27px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 11.25rem;
  display: block;
  position: absolute;
  top: 2.188rem;
  right: -2.313rem;
  height: 27px;
}
.ribbon-new span {
  color: #fff;
  background: #FA9F42
  ;
  background: linear-gradient(#FA9F42
  0%, #FA9F42 100%);
  box-shadow: 0 0.188rem 0.625rem -0.313rem hsl(0, 0%, 0%);
}

.ribbon span::before {
  border-left: 0.188rem solid hsl(78, 89%, 35%);
  border-right: 0.188rem solid transparent;
  border-bottom: 0.188rem solid transparent;
  border-top: 0.188rem solid hsl(78, 89%, 35%);
}

.ribbon span::after {
  border-left: 0.188rem solid transparent;
  border-right: 0.188rem solid hsl(78, 89%, 35%);
  border-bottom: 0.188rem solid transparent;
  border-top: 0.188rem solid hsl(78, 89%, 35%);
}



.ribbon1 {
  position: absolute;
  right: -1.75rem;
  top: -1.75rem;
  z-index: 1;
  overflow: hidden;
  width: 140px;
  height: 140px;
  text-align: right;
}
.ribbon1 span {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  line-height: 27px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 11.25rem;
  display: block;
  position: absolute;
  top: 2.188rem;
  right: -2.313rem;
  height: 27px;
}
.ribbon-new1 span {
  color: #fff;
  background: hsl(75, 88%, 42%);
  background: linear-gradient(#054a91 0%, #054a91 100%);
  box-shadow: 0 0.188rem 0.625rem -0.313rem hsl(0, 0%, 0%);
}

.ribbon1 span::before {
  border-left: 0.188rem solid hsl(78, 89%, 35%);
  border-right: 0.188rem solid transparent;
  border-bottom: 0.188rem solid transparent;
  border-top: 0.188rem solid hsl(78, 89%, 35%);
}

.ribbon1 span::after {
  border-left: 0.188rem solid transparent;
  border-right: 0.188rem solid hsl(78, 89%, 35%);
  border-bottom: 0.188rem solid transparent;
  border-top: 0.188rem solid hsl(78, 89%, 35%);
}


@media only screen and (max-width : 1024px){
  
  .imagePreviewUrl img{
    object-fit: contain !important;
    background: #4c4c4c!important;
    width: 120px;
      height: 120px;
  }
  .ftr{
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    margin: 0px 0px 00px 0px;
}
.foot-content {
  margin: 5px 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: normal;
  text-align: center;
  width: 100%;
  margin: 20px 0px;
  margin-bottom: 50px;
}

  .minhg{
    position: relative !important;
    z-index: 999 !important;
  }
  .hf1{
    height:50px;
  }
  .embla__slidetS {
    flex: 0 0 100%;
    min-width: 0;
    margin-left: 0rem;
    background-color: rgb(0 0 0 / 50%);
    height: max-content;
}
  .bgw12{
    margin-top: 20px!important;
  }
  .imgp{
    min-width: 100% !important;
    padding: 4px;
  }
  .embla__slide__imgba-2 {
    border-radius: 0rem;
    display: block;
    height: 15rem !important;
    width: 100%;
    object-fit: cover;
  }
  .imgn{
    min-width: 100% !important;
    
    }
    .imgm{
      min-width: 100% !important;
    }
  .featuress2 li {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    padding-left: 5% !important;
    height: 55px;
}
  .CousellingButton a {
    color: #fff;
    background: #ed1923;
    padding: 10px 20px !important;
    border-radius: 20px;
    position: sticky;
    font-size: 13px !important;
    text-transform: capitalize !important;
}
.CousellingButton1 a {
  color: #fff;
  background: #ed1923;
  padding: 10px 20px !important;
  border-radius: 20px;
  position: sticky;
  font-size: 13px !important;
  text-transform: capitalize !important;
}
  .mainCourseLeft1 img{
    width: 100%;
    height: 150px !important;
    object-fit: cover;
    border-radius: 10px;
  }
  .desg{
    height:795px !important;
  }
  .mbtextlr{
    display: flex !important;
    flex-direction: column !important;
  }
  .hdf{
    font-size: 12px !important;
    font-weight: 900;
    }
  .CousellingButton1 {
    width: 100% !important;
    margin: 40px auto 40px auto;
    font-size: 16px;
    display: flex !important;
    font-weight: 600;
    align-items: center !important;
    backdrop-filter: none !important;
    justify-content: center !important;
}
    .CountryLogos li {
      display: flex;
      align-items: center;
      justify-content: center;
      /* margin-bottom: 15px; */
      flex-direction: column;
      width: 90px !important;
      height: 70px;
      padding: 0px !important;
      background: none !important;
      margin: 5px auto !important;
  }
  .CountryLogos img {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
  
}
  }
  .left1 {
    width: 100%!important; 
}
.right1 {
  width: 100%!important; 
}
  .mar-t{
    margin-top:20px;
  }
  .mainggame h4 {
    color: #fff;
    font-size: 19px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 1.5;
    text-align: center;
    margin-top: 0px;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 8px;
    font-family: 'Montserrat', sans-serif !important;
}
  .mainggame h6 {
    color: #fff;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    margin-top: 0px;
    text-transform: capitalize;
    letter-spacing: 1px;
    font-family: 'Montserrat', sans-serif !important;
}
  .CourseesOverView {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10px !important;
}
  #chapter3{
    height: auto !important;

  }
 
  .incontent p{
    color: #fff !important;
      font-weight: 600;
      font-size: 16px !important;
      padding: 10px 30px;
      text-align: center;
  }
  .alkksk {
    background: #222;
    padding: 20px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    line-height: 28px;
    width: 100%;
    font-size: 16px !important;
    margin-bottom: 20px;
}
  .courseUpdates1 ul li img {
    width: 68% !important;
    height: 50px;
    object-fit: scale-down;
    object-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
  .Objectives li{
    font-size: 16px !important;
  }
  .normaltext {
    font-size: 16px !important;
    font-weight: 400;
    line-height: 28px;
    width: 95% !important;
    text-align: left !important;
    color: #fff;
    margin-top: 20px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
 
  .button.size200 {
    font-family: 'Montserrat', sans-serif;
    font-size: 14.5px;
    font-weight: 500;
    height: 45px;
    line-height: 1.48;
    padding-left: 24px;
    padding-right: 24px;
    text-transform: uppercase;
    margin-top: 20px;
}
  /* .tsetm{
    height: fit-content;
  } */


.bgw {
  margin-top: 0px;
}


.CourseesOverViewA{
  display: flex;
  flex-direction: column;
}
.suprtsMobile{
  width: 90%;
  margin: 0 auto;
  margin-bottom: 30px;
  color: #fff;
  text-align: center;
  font-size: 19px;
  font-weight: 500;
  margin-top: 0px;
}
#Featured-Mentors{
  display: none;
}
.t-c1{
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgn{
  min-width: 33.33%;
  
  }
  .imgm{
    min-width: 33.33%;
  }
  .btn-floating-whatsapp {
    align-items: center;
    background-color: #25d366;
    border-radius: 50%;
    bottom: 1.3rem !important;
    box-shadow: 0 4px 10px 0 rgba(0,0,0,.15);
    cursor: pointer;
    display: flex;
    height: 60px;
    justify-content: center;
    position: fixed;
    right: 2rem;
    user-select: none;
    width: 60px;
    z-index: 9998;
}
.mainPanel1{
  display: flex;
}
#mobileview{
  display: none;
}
#desview{
  display: block;
}

.sidebarview1{
  display: flex;
  width: 100%;
  /* background-image: url(../../../assets/img/banners/Concept.webp); */
  background-color: #222;
  background-position: center;
  padding: 0px 15px;
  border-radius: 0px;
}
.sidebarview1 ul{
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(209, 213, 219, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0%;
  width: 100%;
  padding: 20px;
}

.sidebarview1 ul li{
    padding-bottom: 35px;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    z-index: 9;
}

.videomain1{
  width: 100% !important;
  height: 217px;
  padding-top: 35px;
}
.frmd{
  width: 30%;
  margin: 0px auto;
}
.mt-top{
  margin-top: 0px !important;
}
.pdleft{
  padding-left: 20px !important;
}
.suprtsMobiless {
  width: 90%;
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin: 30px auto;
}

.formGrid1{
  width: 90%;
  display: flex;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: 1rem;
  padding: 0.7rem 0% 0rem 0%;
}
.formGrid1 label{
color: #fff;
font-size: 17px;
margin-bottom: 10px;
display: flex;
letter-spacing: 1px;
width: 100%;
}
.formGrid1 select{
background: none;
width: 100%;
padding: 5px;
border: 1px solid #f2f2f2;
border-radius: 2px;
height: 42px;
display: block;
color: #fff;
font-size: 16px;
}
.formGrid1 select option{
font-size: 12px;
font-weight: 800;
color: #000;
}
.formGrid1 select option:hover{
font-size: 12px;
font-weight: 800;
 color: #000;
}
select {
color: #474747;
}
option:not(:first-of-type) {
color: black;
}
/* the modification */
option:first-of-type {
display: none;
}


#pointerss h1 {
  width: 100%;
  display: flex;
}
#pointerss h1 {
  font-size: 25px;
  font-weight: 900;
  color: #fff;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: left;
  width: 100%;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#pointerss:before {

    content: "";
    position: absolute;
    right: -100px;
    bottom: 0;
    width: 0;
    height: 39px;
    border-left: 100px solid #222;
    border-top: 76px solid transparent;
    border-bottom: 76px solid transparent;

}


#pointerss {

    width: 680px;
    height: 153px;
    position: relative;
    background: #222;
    transition: box-shadow 0.3s ease;
    transition: width 0.3s ease;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 75px;
    margin-bottom: 19px;

}

.gllart {
  margin: 0px auto !important;
  --_size: 80px; /* width and heigh of iamges */
  --_grid-cols: repeat(3, 1fr);
  --_grid-areas: "large large large" "large large large" "large large large"
    "thumb-1 thumb-2 thumb-3" "thumb-4 thumb-5 thumb-6"
    "thumb-7 thumb-8 thumb-9";
  --_gap: 0.3rem;
  --_info-top: 0;
  --_info-bottom: auto;
  --_info-translate: -100px;

  width: 95%;
  max-width: 100%;
  min-width: 95%;
  display: grid;
  grid-template-columns: var(--_grid-cols);
  grid-template-areas: var(--_grid-areas);
  position: relative;
  overflow: hidden;
}
@media (min-width: 720px) {
  .gllart {
    --_size: 376px;
    --_grid-cols: repeat(3, 1fr);
    --_grid-areas: "thumb-1 thumb-2 thumb-3 large large large"
      "thumb-4 thumb-5 thumb-6 large large large"
      "thumb-7 thumb-8 thumb-9 large large large";
    --_info-top: auto;
    --_info-bottom: 0;
    --_info-translate: 100px;
  }
}

.gllart > .gart {
  cursor: pointer;
  height: 350px;
  /* aspect-ratio: 1; */
}
.gllart > .gart:nth-child(1) {
  grid-area: thumb-1;
}
.gllart > .gart:nth-child(2) {
  grid-area: thumb-2;
}
.gllart > .gart:nth-child(3) {
  grid-area: thumb-3;
}
.gllart > .gart:nth-child(4) {
  grid-area: thumb-4;
}
.gllart > .gart:nth-child(5) {
  grid-area: thumb-5;
}
.gllart > .gart:nth-child(6) {
  grid-area: thumb-6;
}
.gllart > .gart:nth-child(7) {
  grid-area: thumb-7;
}
.gllart > .gart:nth-child(8) {
  grid-area: thumb-8;
}
.gllart > .gart:nth-child(9) {
  grid-area: thumb-9;
}

.gllart > .gart img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 500ms ease-in-out;
  object-position: top;
}
.gllart > .gart > div {
  position: absolute;
  right: 0;
  top: var(--_info-top);
  bottom: var(--_info-bottom);
  width: calc((var(--_size) * 3) + var(--_gap) * 2);
  z-index: 10;
  background-color: #00000099;
  padding: 1rem;
  font-size: 0.85rem;
  font-weight: 300;
  opacity: 0;
  translate: 0 var(--_info-translate);
  transition: 300ms ease-in-out;
}
.gllart > .gart > div h2 {
  font-size: 1rem;
  font-weight: 500;
}
.gllart > .gart > div a {
  color: white;
  transition: scale 300ms;
  display: inline-block;
  transform-origin: bottom left;
}
.gllart > .gart > div a:hover {
  scale: 1.25;
}


/* .gllart > .gart::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: calc((var(--_size) * 3) + var(--_gap) * 2);
  height: calc((var(--_size) * 3) + var(--_gap) * 2);
  aspect-ratio: 1;
  background-image: var(--bg-img);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
} */

.lngartimg{
  height: 710px !important;
}
.imgwid{
  width:750px !important;
}
.videomain{
  margin: 0 auto;
  width: 700px;
  margin-bottom: 30px;
}
.martimg{
  margin-top: 359px;
}

.mainCourse1{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #222;
  border: 1px solid rgba(86, 61, 124, .2);
  padding: 20px;
  margin-bottom: 20px;
  height: 269px;
}
.mainCourse1:hover .CourseLink1{
  background: #333;
  color: #ed1923;
}
.mainCourse1:hover .CourseHead1{
  color: #ed1923;
}
.mainCourseLeft1{
  width: 32%;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainCourseLeft1 img{
  width: 100%;
  height: 80%;
  object-fit: cover;
  border-radius: 10px;
}
.mainCourseRight1{
  width: 68%;
  height: 225px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 3%;
  flex-direction: column;
}
.mainCourseRight1 h2{
  color: #fff;
  font-size: 27px;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;
  line-height: 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.mainCourseRight1 p{
  color: #fff;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 10px;
  line-height: 30px;
  width: 90%;
}
.CourseLink1{
  color: #fff;
  margin: 20px 0px;
  text-transform: uppercase;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none !important;
  font-size: 16px;
}
.CourseLink1:hover{
  color: #ed1923;
  text-decoration: underline;
}
.CourseLink1 svg{
  margin-left: 7px
}
.mainHeadingTotall{
  font-size: 48px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  letter-spacing: 1px;
  text-align: center;
}
.form-stcc{
  padding-top: 10px;
  padding-bottom: 0px;
  background-color: #222;
  border: 1px solid rgba(86, 61, 124, .2);
}
.courseUpdatesl ul li img {
  width: 80px;
  height: 40px;
  object-fit: scale-down;
  object-position: center;
}
.courseUpdatesl ul li {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.courseUpdatesl ul {
  display: flex;
  /* grid-template-columns: repeat(2,1fr); */
  grid-column-gap: 20px;
  column-gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.courseUpdatesl ul li span {
  font-size: 19px;
  font-weight: 700;
  color: #fff;
  text-align: center;
}

.plu1s{
  display: flex;
  align-items: center;
  justify-content: center;
  }
  .plu1s div{
    border-right: 1px solid #343232;
    line-height: 153px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    background: #343232;
    }
    .plu1s span{
      font-size: 48px;
      color: #fff;
      }
      .video-container {
        position: relative;
        width: 100%; /* Set the width as needed */
        max-width: 800px; /* Optional: Set max width */
      }
      
      video {
        width: 100%;
        height: auto;
      }
      
      .overlay-image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        opacity: 0.8;
        transition: opacity 0.3s ease;
      }
      
      .overlay-image:hover {
        opacity: 1;
      }
      .MainVideoSection {
        display: flex;
        width: 80%;
        color: #fff;
        margin: 0 auto;
        flex-direction: column;
    }
    .MainVideoSection h3 {
      font-size: 24px;
      font-weight: 600;
      width: 80%;
      line-height: inherit;
      margin: 30px auto 30px auto;
      text-align: center;
  }
  .videomain2 {
    width: 100% !important;
    height: 720px;
    padding-top: 0px;
    position: relative;
    /* overflow: hidden; */
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.videomain2 video{
    width: 1030px;
    height: 480px;
    margin: 0 auto;
}
.overlay-image2-1 {
  position: absolute;
  width: 100%;
  opacity: 1;
  left: 0px;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  object-position: top;
}
.mainrec2{
  margin-top: 30px;
}
.mainrec22{
  margin-top: 0px;
}
.recuitersList-2 img {
  /* width: 220px;
  height: 80px; */
  width: revert-layer;
  height: revert-layer;
  padding-left: 10px;
  padding-right: 10px;
  object-fit: contain;
  object-position: center center;
}

.featuress2 {
  width: 85%;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0rem;
  padding: 1rem 0% 1rem 0px;
  /* margin-top: 20px; */
  background: #222;
  align-items: center;
  grid-row-gap: 0px;
  margin: 0 auto;
  margin-bottom: 60px;
}
.featuress3 {
  width: 85%;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0rem;
  padding: 1rem 0% 1rem 0px ;
  /* margin-top: 20px; */
  background: #222;
  align-items: center;
  grid-row-gap: 0px;
  margin: 0 auto;
}
.featuress3g {
  width: 43%;
  margin: 0px auto;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0rem;
  padding: 1rem 0% 1rem 0px ;
  /* margin-top: 20px; */
  background: #222;
  align-items: center;
  grid-row-gap: 0px;
 
}
.featuress3u {
  width: 54%;
  margin: 0px auto;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0rem;
  padding: 1rem 0% 1rem 0px ;
  /* margin-top: 20px; */
  background: #222;
  align-items: center;
  grid-row-gap: 0px;
 
}

.featuress2 li{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding-left: 15%;
}
.featuress3 li{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding-left: 5%;
}
.featuress3g li{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding-left: 12%;
}
.featuress3u li{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  padding-left: 12%;
}
.featuresscon2{
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 20px;
  color: #fff;
  border-radius: 10px;
  width: 90%;
  font-size: 19px;
  font-weight: 500;
}
.featuress2 svg{
  font-size: 45px;
  margin-right:10px;
  }
  .featuress3 svg{
    font-size: 45px;
    margin-right:10px;
    }
    .featuress3g svg{
      font-size: 45px;
      margin-right:10px;
      }
      .featuress3u svg{
        font-size: 45px;
        margin-right:10px;
        }
  .imgp-2{
    min-width: 34%;
    padding: 8px;
  }
  .imgp-22{
    min-width: 33%;
    padding: 8px;
  }
  .embla__parallax__imgba-2 {
    max-width: none;
    flex: 0 0 calc(115%);
    object-fit: contain !important;
    object-position: top;
    
  }
  .emblaba-2 {
    max-width: 100%;
    margin: auto;
    --slide-height: 19rem !important;
    --slide-spacing: 1rem;
    --slide-size: 30%;
    position: relative;
}
.embla__viewportba-2 {
  overflow: hidden;
}
.embla__containerba-2 {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(1rem * -1);
}
.embla__parallaxba-2 {
  border-radius: 0rem;
  height: 100%;
  overflow: hidden;
}
.embla__parallax__layerba-2 {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #222;
}
.embla__slide__imgba-2 {
  border-radius: 0rem;
  display: block;
  height: 13rem;
  width: 100%;
  object-fit: cover;
}
.embla__slide__imgba-22 {
  border-radius: 0rem;
  display: block;
  height: 220px;
  width: 100%;
  object-fit: cover !important;
  object-position: top;
}
.buzzdiv-2 {
  position: absolute;
  background: rgb(0, 0, 0, 0.7);
  color: #fff;
  padding: 15px 30px;
  bottom: 0px;
  width: 100%;
}
.buzzdiv-2 h1 {
  font-size: 19px;
  margin: 5px 0px;
  font-weight: 500;
  text-align: center;
  height: 30px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__controlsba-2 {
  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  grid-gap: 1.2rem;
  gap: 1.2rem;
  margin-top: 1.8rem;
}
.embla__buttonsba-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.6rem;
  gap: 0.6rem;
  align-items: center;
  position: absolute;
  z-index: 99;
  top: 46.2%;
  width: 97%;
  left: 0px;
  margin: 0 auto;
}
.embla__buttonba-2 {
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: 3.6rem;
  height: 3.6rem;
  z-index: 1;
  border-radius: 50%;
  color: var(--text-body);
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button--prevba-2 {
  left: 10px;
  position: absolute;
}
.embla__button__svgba-2 {
  width: 65%;
  height: 65%;
  color: #fff;
}
.embla__button--nextba-2 {
  right: -30px;
  position: absolute;
}
.embla__button__svgba-2 {
  width: 65%;
  height: 65%;
  color: #fff;
}

.faq-container {
  max-width: 85%;
  margin: 0 auto;
}

.faq-item {
    padding: 15px 0;
  width: 100%;
  background: #222;
    margin-bottom: 10px;
}

.faq-question {
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-transform: capitalize;
  font-size: 19px;
  border: 1px solid #222;
  padding: 10px 30px;
  text-transform: math-auto;
}
.faq-question svg{
  font-size: 29px;
}
.faq-question:hover {
  color: #ed1923;
}

.faq-answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  line-height: 28px;
  
}

.faq-answer.open {
  max-height: max-content; /* Adjust as needed */
  border-top:1px solid#363434;
  padding: 10px 30px;
}
.fom-ad-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: 70px;
}
.containers-2 {
  max-width: 850px;
  padding: 28px;
  margin: 0 28px;
  border-radius: 0px;
  overflow: hidden;
  background: #222;
  box-shadow: 0 15px 20px rgba(0, 0, 0, .6);
}

.formGrid-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 1rem;
  padding: 0.7rem 0% 0rem 0%;
}
.formGrid-2 label {
  font-size: 17px;
  margin-bottom: 7px;
  font-weight: 500;
  color: #fff;
  display: inline-block;
}
.formGrid-2 select {
  font-size: 17px;
  margin-bottom: 7px;
  font-weight: 500;
  background: none;
  display: inline-block;
  border: 1px solid #cccccc;
  height: 42px;
}
.Celebrating{
  font-size: 24px;
    font-weight: 600;
    background: #ec1925;
    padding: 3px 15px;
    margin: 0px auto;
    font-style: italic;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    
}
.Celebrating span{
  font-size: 30px;
  font-weight: bold;
  color: #000;
  font-style: normal;


}
.CousellingButton {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 15px 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  z-index: 9;
  backdrop-filter: blur(7.5px);
  background-color: #222;
}
.CousellingButton a{
  color: #fff;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  background-image: linear-gradient(101deg, #ec242d, #e85e64);
  border-radius: 74px;
  justify-content: center;
  align-items: center;
  height: 55px;
  padding: 10px 80px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px #93242b;
  position: sticky;
}
.CousellingButton1 a {
  color: #fff;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  background-image: linear-gradient(101deg, #ec242d, #e85e64);
  border-radius: 74px;
  justify-content: center;
  align-items: center;
  height: 55px;
  padding: 10px 80px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  text-decoration: none;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px #93242b;
}
.CousellingButton1{
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-top: 0px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
  z-index: 9;
  backdrop-filter: blur(7.5px);

}
.mainHeadingTotall-2 {
  font-size: 32px;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 40px;
    width: 85%;
    letter-spacing: 1px;
    text-align: center;
    margin: 40px auto 40px auto;
    z-index: 9;
    position: relative;
}


.CountryLogos{
  width: 85%;
  margin-bottom: 0px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2rem;
    padding: 0rem 0% 0rem 0%;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
}
.CountryLogos li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  flex-direction: column;
  width: 100%;
  background: #222;
  padding: 10px;
}
.CountryLogos img {
  width: 100%;
  margin-right: 0px;
  height: 130px;
  object-fit: cover;
}





.CountryLogoss{
  width: 85%;
  margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 2rem;
    padding: 0rem 0% 0rem 0%;
    margin-top: 20px;
    margin: 0 auto;
}
.CountryLogoss li {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0px;
  flex-direction: column;
  width: 100%;
  background: #222;
  padding: 10px;
}
.CountryLogoss img {
  width: 100%;
  margin-right: 0px;
  height: 78px;
  object-fit: scale-down;
}



.CountryLogos-2 {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0px 5px 0px;
  color: #fff;
  border-radius: 10px;
  width: 95%;
  font-size: 19px;
  font-weight: 500;
}
.testfooter{
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.Testoptions {
  color: #f00;
}
.faq2 ul{
  width: 100%;
  margin: 0 auto;
}
.faq2 ul li{
  line-height: 27px;
  margin-bottom: 10px;
  font-size: 17px;
}
.faq2 ul li span{
  color: #f00;
}
@media screen and (max-width: 1366px) {

  .head-container {
    width: 100% !important;
    margin: 0 auto;
    padding: 0px 0;
}
  } 

  .left1{
    display: flex;
    flex: 1; 
  padding: 20px;
  }
  
  .right1{
    display: flex;
    flex: 1; 
  padding: 20px;
  }
  .desg{
   
  }
  .lefth{
    margin-left: 100px; 
  }
  .lefthd{
    display: flex;
    justify-content: center;
    align-items: flex-start !important;
    flex-direction: row;
  }
  .button.brand:hover{
    animation: ripple 1s forwards;
  }
  @keyframes ripple {
    0% { transform: scale(1); }
    100% { transform: scale(1.2); }
  }


  /* Basic styles for the container */
.phone-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.icon, .phone-number {
  transition: transform 0.3s ease, color 0.3s ease;
}

/* Hover effect */
.phone-container:hover {
  transform: scale(1.1);
}

.phone-container:hover .icon {
  transform: rotate(360deg);
  color: #007bff;
}

.phone-container:hover .phone-number {
  transform: translateX(10px);
  color: #007bff;
}
.md1 svg:hover{
  color:#ED1B24;
  transform: scale(1.1);
}
.md2 svg:hover{
  color:#ED1B24;
  transform: scale(1.1);
}
.md3 svg:hover{
  color:#ED1B24;
  transform: scale(1.1);
}
.md4 svg:hover{
  color:#ED1B24;
  transform: scale(1.1);
}
.md5 svg:hover{
  color:#ED1B24;
  transform: scale(1.1);
}
.md6 svg:hover{
  color:#ED1B24;
  transform: scale(1.1);
}
/* src/MultiStepForm.css */

.progress-container {
  width: 100%;
  margin-bottom: 20px; /* Space between the progress bar and the step title */
}

.progress-bar {
  width: 100%;

  overflow: hidden;
}

.progress {
  height: 30px; /* Height of the progress indicator */
  background-color: #ed1923;
  transition: width 0.3s ease; /* Smooth transition for the progress */
}

.steps-list {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}

.step {
  position: relative;
  flex: 1;
  text-align: center;
  font-size: 14px;
  color: #999; /* Default color for step labels */
}

.step.active {
  color: #ed1923; /* Highlight color for active steps */
  font-weight: bold; /* Make the active step bold */
}

.multi-step-form {
  background: #222;
  border-radius: 0px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 100%;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.formMain input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc !important;
  border-radius: 4px;
}
.formMain textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc !important;
  border-radius: 4px;
}

button {
  padding: 10px 15px;
  border: none;
  background-color: #ee1827;
  ;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #ee1827;

  color:#fff !important;
}

/* Error message styles */
.error {
  color: red;
  font-size: 12px;
  margin-top: -8px; /* Adjust position */
  margin-bottom: 10px; /* Space below error message */
}

.hdform{
  color:#fff;
  font-size: 20px;
}
.fieldsf{
  margin-right: 10px;
  width:50%;
}
.fieldss{
  width:50%;
}
.multi-step-form input{
 color:#fff !important;
 border:1px solid #6c6666 !important;
}
.formGrid .sel:focus{
  outline: 1px solid #223040!important;
  color: #fff !important;
}

/* Global Styles */
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Container Styling */
.MuiContainer-root {
  background-color: #222 !important;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  width:1200px !important;
}



.MuiTab-root {
  text-transform: none;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.Mui-selected {
  color: #ec1925 !important;
  font-weight: bold;
}

/* Form Field Styling */
.MuiTextField-root {
  margin: 12px 0 !important;
}

.MuiInputLabel-root {
  font-size: 1.2rem;
  color: #7f7f7f;
}

.MuiInputBase-root {
  font-size: 1rem;
}

.MuiFormControl-root {
  width: 100%;
}

/* Button Styling */
.MuiButton-containedPrimary {
  background-color: #ec1925 !important;
  color: #fff;
  font-weight: 600;
  margin: 16px 0;
  padding: 10px 16px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.MuiButton-containedPrimary:hover {
  background-color: #ec1925;
}

.MuiButton-containedSecondary {
  background-color: #f44336;
  color: #fff;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 4px;
}

.MuiButton-containedSecondary:hover {
  background-color: #d32f2f;
}

/* Checkbox Styling */
.MuiFormControlLabel-label {
  font-size: 0.9rem;
  /* color: #555; */
  color: #fff;
}

/* Error Message Styling */
.MuiFormHelperText-root {
  color: #d32f2f;
  font-size: 0.8rem;
}

/* Typography Styling */
.MuiTypography-h6 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: #fff;
}

/* Modal Styling */
.MuiModal-root {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 24px;
  text-align: center;
}

.modal-content h6 {
  margin-bottom: 16px;
}

.modal-content button {
  margin-top: 16px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .MuiContainer-root {
      width: 100% !important;
      padding: 10px;
      box-shadow: none;
  }
}

/* Button Styling */
.MuiButton-contained {
  background-color: #ec1925;
  color: #fff;
  font-weight: 600;
  padding: 10px 16px;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin: 8px;
}

.MuiButton-contained:hover {
  background-color: #ec1925;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-disabled {
  pointer-events: none;
  cursor: default;
  color: #fff !important;
  font-weight: bold;
  font-size: 18px;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: rgb(59 59 60);
  border-width: 2px;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  inset: -5px 0px 0px;
  margin: 0px;
  padding: 0px 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  min-width: 0%;
  border-color: rgb(232 224 224 / 23%);
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color:#fff;
}
.CousellingButton10 a{
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px;
}
.CousellingButton10 a:hover{
  color: #ee1826;
}
@media only screen and (max-width : 1024px){
  .kattt:after {
    display:none !important;
}
.mainrec22{
  margin-top: 65px;
}
.siva-0:after {
display :none !important;
}
}
.siva-0{
  float: left;
  text-align: center !important;
  margin: 0px 14px 0px 3px !important;
  padding: 0px !important;
  width: calc(25% - 4.5px) !important;
  position: relative !important;
  background: #eb1a23 !important;
  color: #fff !important;
  overflow: visible !important;
  font-size: 18px;
}
.siva-1{
  width: 25% !important;
  float: left !important;
  text-align: center !important;
  margin: 0px 14px 0px 3px !important;
  padding: 0px !important;
  width: -webkit-calc(20% - 4.5px) !important;
  width: -moz-calc(20% - 4.5px) !important;
  width: calc(25% - 4.5px) !important;
  position: relative !important;
  background: #eb1a23 !important;
  color: #fff !important;
  overflow: visible !important;
  border-left: 0px solid #222!important;
  font-size: 18px;
  font-weight: bold;
}
.siva-2{
  width: 25% !important;
  float: left !important;
  text-align: center !important;
  margin: 0px 14px 0px 3px !important;
  padding: 0px !important;
  width: -webkit-calc(20% - 4.5px) !important;
  width: -moz-calc(20% - 4.5px) !important;
  width: calc(25% - 4.5px) !important;
  position: relative !important;
  background: #eb1a23 !important;
  color: #fff !important;
  overflow: visible !important;
  border-left: 0px solid #222!important;
  font-size: 18px;
  font-weight: bold;
}
.siva-3{
  width: 25% !important;
  float: left !important;
  text-align: center !important;
  margin: 0px 14px 0px 3px !important;
  padding: 0px !important;
  width: -webkit-calc(20% - 4.5px) !important;
  width: -moz-calc(20% - 4.5px) !important;
  width: calc(25% - 4.5px) !important;
  position: relative !important;
  background: #eb1a23 !important;
  color: #fff !important;
  overflow: visible !important;
  border-left: 0px solid #222!important;
  font-size: 18px;
  font-weight: bold;
}
.kattt{
  width: 25%;
  float: left;
  text-align: center;
  margin: 0px 7px !important;
  padding: 0px;
  width: -webkit-calc(20% - 4.5px);
  width: -moz-calc(20% - 4.5px);
  width: calc(25% - 4.5px);
  position: relative !important;
  background: #454141  !important;
  color: #fff !important;
  overflow: visible !important;
  border-top: 1px solid #333 !important;
  border-bottom: 1px solid #333 !important;
}

.siva-0:after {
  border-left: 14px solid #eb1a23;
}
.siva-0:after {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  right: -14px !important;
  width: 0 !important;
  height: 0 !important;
  border-top: 25px solid transparent !important;
  border-bottom: 25px solid transparent !important;
  border-left: 14px solid #eb1a23 !important;
  z-index: 2 !important;
}
.siva-1:before {
  right: auto;
  left: 0;
  border-left: 14px solid #ff0;
  z-index: 0;
}
.siva-1:after {
  content: ""!important;
  position: absolute!important;
  top: 0!important;
  right: -13px !important;
  width: 0 !important;
  height: 0 !important;
  border-top: 25px solid transparent !important;
  border-bottom: 25px solid transparent !important;
  border-left: 14px solid #eb1a23 !important;
  z-index: 2 !important;
}
.siva-2:after {
  content: ""!important;
  position: absolute!important;
  top: 0!important;
  right: -13px!important;
  width: 0!important;
  height: 0!important;
  border-top: 25px solid transparent!important;
  border-bottom: 25px solid transparent!important;
  border-left: 14px solid #eb1a23!important;
  z-index: 2!important;
}
.siva-3:after {
  content: ""!important;
  position: absolute!important;
  top: 0!important;
  right: -13px!important;
  width: 0!important;
  height: 0!important;
  border-top: 25px solid transparent!important;
  border-bottom: 25px solid transparent!important;
  border-left: 14px solid #eb1a23!important;
  z-index: 2!important;
}
.kattt:after {
  content: ""!important;
  position: absolute!important;
  top: 0!important;
  right: -13px!important;
  width: 0!important;
  height: 0!important;
  border-top: 24px solid transparent!important;
  border-bottom: 24px solid transparent!important;
  border-left: 14px solid #454141!important;
  z-index: 2!important;
}
.kattt::before {
  right: auto;
  left: 0;
  border-left: 14px solid #fff;
  z-index: 0;
}
.css-19tkfpu-MuiFormLabel-root-MuiInputLabel-root.Mui-focused{
  color: #fff;
}
.css-15reu3p-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #fff;
}
.css-1oteqc8.Mui-focused{
  color:#fff !important;
}
.css-1kgp0bx-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff;
  border-width: 2px;
}
.css-1aquho2-MuiTabs-indicator {
  border:1px solid#ED1B24;
}

.FileUpload{

}

/* src/DocumentUploadForm.css */

.form-groupH {
  display: flex;
  margin-bottom: 15px;
  position: relative;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  border-bottom: 1px solid #555;
  padding-bottom: 20px;
  align-items: center;
}
.form-groupH label{
    font-size: 14px;
    color: #fff;
} 

.FileUpload input[type="file"] {
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 5px;
}

.file-name {
  margin-top: 5px;
  font-size: 14px;
  color: #007bff;
  display: inline-block;
  position: absolute;
  left: 500px;
  top: 0px;
}

.FileUpload button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}
.tcontent button {
  background: none;
  font-size: 13px;
  text-align: right;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  text-decoration: underline;
  letter-spacing: 1px;
  margin: 0px;
  padding: 0px 0px;
}

.embla__buttonssLL {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.6rem;
  gap: 0.6rem;
  align-items: center;
  position: absolute;
  z-index: 99;
  top: 40%;
  width: 91%;
  left: 4%;
  margin: 0 auto;
  }
  .tetstL{
    padding-bottom: 40px;
  }
  .TestM{
    margin-top: 40px;
  }
  .recuitersList-20 .rfm-child{
    width: 250px;
    background: #222;
    margin-right: 10px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .recuitersList-20 .rfm-child img{
    width: 90%;
    padding: 10px 20px;
    object-fit: contain;
  }

  .faq-answer {
    outline: none; /* Removes default outline */
  }
  
  .faq-answer:focus {
    outline: none; /* Ensures no focus outline on click */
    background-color: transparent; /* Removes blue background */
  }
  .faq-question {
    outline: none;
    transition: none; /* Disables any CSS transition effects */
  }
  
  .faq-question:focus, .faq-question:active {
    outline: none;
    background-color: inherit; /* Keeps background consistent */
  }
  .faq-question {
    outline: none;
    background: inherit;
    user-select: none;
  }
  
  /* Remove focus only for mouse interactions */
  .faq-question:focus-visible {
    outline: none;
  }
  .rfm-marquee {
    flex: 0 0 auto;
    min-width: auto !important;
}
.s1t select:focus {
  color: #fff; 
}
.s1t select{
color:#555546;
}
.hvref:hover{
  color:#ec1923; 
}
.tetstL1{
  padding-top: 20px;
  padding-bottom: 15px;
}
.closeb:hover{
  color:#000 !important;
  background-color: #fff;
}
.chp{
  font-size: 1.1rem;
}
.discl{
  font-size: 15px;
}
.subop{
  font-size: 1.1rem;
}
.imagePreviewUrl img{
  object-fit: contain !important;
  background: #4c4c4c!important;
  width: fit-content;
}
.cont1:hover{
  cursor: pointer;
  font-weight: 900;
  font-size: 19px;
  color:#ED1B24;
}
.suprts1 p{
  color: #f9f9f9 !important;
  font-family: 'Montserrat', sans-serif !important;
  line-height: 27px !important;
}
.suprts1 span{
  color: #f9f9f9 !important;
  font-family: 'Montserrat', sans-serif !important;
  line-height: 27px !important;
}
.suprts1 h3{
  color: #fff !important;
  font-family: 'Montserrat', sans-serif !important;
  margin-bottom: 10px !important;
  line-height: normal !important;
  font-size: 27px !important;
  margin-top: 10px !important;
}
/* Scroll Progress Bar */
.scroll-progress-container {
  position:fixed;
  top: 80px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #cdcdcd;
  z-index: 99999;
}

.scroll-progress-bar {
  height: 5px;
  background: #ec1923;
  width: 0%;
  transition: width 0.2s ease-out;
}

/* Page Content */
.content {
  height: 200vh; /* Extra height to allow scrolling */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}
.krish a{
  color:#fff;
}
.krish a:hover{
  color:#e61d23;
}
@media (max-width: 1366px) {
  .formGridLand {
    width: 645px;
  }
}

@media (min-width: 1920px) {
  .formGridLand {
    width: 810px;
  }
}
.hnone{
  pointer-events: none !important;
  color:#fff !important;
}